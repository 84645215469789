<template>
  <div id="viewer-host">
    <JSViewer :exportsSettings="exportsSettings" ref="reportViewer"></JSViewer>
  </div>
</template>

<script>
/*eslint-disable */
import { Viewer } from '@grapecity/activereports-vue'
import '@grapecity/activereports/styles/ar-js-ui.css'
import '@grapecity/activereports/styles/ar-js-viewer.css'
import { ref } from "vue";
import { useRoute } from 'vue-router'

export default {
  name: 'esteam_sales_quotation',
  components: { JSViewer: Viewer },
  setup() {
    const route = useRoute()

    const availableExports = ref(["pdf", "html", "tabular-data"]);
    const exportsSettings = ref({
      pdf: {
        // title: "ActiveReportsJS Sample",
        // author: "GrapeCity",
        // subject: "Web Reporting",
        // keywords: "reporting, sample",
        // userPassword: "pwd",
        // ownerPassword: "ownerPwd",
        // printing: "none",
        // copying: false,
        // modifying: false,
        // annotating: false,
        // contentAccessibility: false,
        // documentAssembly: false,
        // pdfVersion: "1.7",
        // autoPrint: true,
        filename: route.params.filename,
      },
      xlsx: {
        filename: route.params.filename,
      },
    });

    return {
      availableExports,
      exportsSettings,
    };
  },  
  async mounted() {
    // Open the report
    // this.$refs.reportViewer.exportsSettings.pdf.fileName = "custom_filename";

    this.$refs.reportViewer
      .Viewer()
      .open("/reports/esteam/ESTeam-Sales_Quotation.rdlx-json", {
        ReportParams: [
          {
            Name: "access_token",
            Value: [this.$route.params.a],
          },
          {
            Name: "document_code",
            Value: [this.$route.params.document_code],
          },
        ],

    });
  },
}
</script>

<!--
const availableExports = ref(["pdf", "html", "tabular-data"]);
const exportsSettings = ref({
  pdf: {
    title: "ActiveReportsJS Sample",
    author: "GrapeCity",
    subject: "Web Reporting",
    keywords: "reporting, sample",
    userPassword: "pwd",
    ownerPassword: "ownerPwd",
    printing: "none",
    copying: false,
    modifying: false,
    annotating: false,
    contentAccessibility: false,
    documentAssembly: false,
    pdfVersion: "1.7",
    autoPrint: true,
    filename: "ActiveReportsJS-Sample.pdf",
  },
  html: {
    title: "ActiveReportsJS Sample",
    filename: "ActiveReportsJS-Sample.html",
    autoPrint: true,
    multiPage: true,
    embedImages: "external",
    outputType: "html",
  },
});

return {
  availableExports,
  exportsSettings,
};
},
};
</script>

<style>
#viewer-host {
 width: 100%;
height: 100%;
}
</style>
-->