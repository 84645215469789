/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import LKKPreliminaryAdvice from '../views/reports/lkk/preliminary_advice.vue'
import LKKSalesInvoice from '../views/reports/lkk/sales_invoice.vue'
import LKKSurvey from '../views/reports/lkk/survey.vue'
import LKKSurveyAdjustment from '../views/reports/lkk/survey_adjustment.vue'
import LKKSurveyPhotos from '../views/reports/lkk/survey_photos.vue'
import LKKSurveyPhotosLandscape from '../views/reports/lkk/survey_photos_landscape.vue'
import LKKExportARInvoices from '../views/reports/lkk/export_ar_invoices.vue'
import LKKExportARSR from '../views/reports/lkk/export_ar_sr.vue'

import BankReconciliation from '../views/reports/hkgrocery/bank_reconciliation.vue'
import HKGROCERYStockClosingBalance from '../views/reports/hkgrocery/stock_closing_balance.vue'
import SalesAnalysisByMonth from '../views/reports/hkgrocery/sales_analysis_by_month.vue'
import SalesHistoryByProduct from '../views/reports/hkgrocery/sales_analysis_by_product.vue'
import SalesAnalysisByClient from '../views/reports/hkgrocery/sales_analysis_by_client.vue'
import PurchaseHistory from '../views/reports/hkgrocery/purchase_history.vue'

import SalesReceipt from '../views/reports/hkeda/sales_receipt.vue'
import Sales from '../views/reports/hkeda/sales.vue'

//ESTEAM
import ESTeamSalesQuotation from '../views/reports/esteam/sales_quotation.vue'
import ESTeamSOA from '../views/reports/esteam/soa.vue'
import ESTeamGST from '../views/reports/esteam/gst.vue'
import ESTeamDebtors from '../views/reports/esteam/debtors.vue'
import ESTeamSalesInvoice from '../views/reports/esteam/sales_invoice.vue'
import ESTeamInventoryStockcard from '../views/reports/esteam/inventory_stockcard.vue'
import ESTeamPurchaseOrder from '../views/reports/esteam/PO.vue'
import ESTeamCategoryListing from '../views/reports/esteam/category_listing.vue'
import ESTeamItemBalanceCostByCategoryRange from '../views/reports/esteam/item_balance_cost_by_category_range.vue'
import ESTeamItemBalanceOnHandsByCategoryRange from '../views/reports/esteam/item_balance_on_hands_by_category_range.vue'
import ESTeamStockValueReportSummary from '../views/reports/esteam/stock_value_report_summary.vue'
import ESTeamProductSalesReportItemSalesReportByDepartment from '../views/reports/esteam/product_sales_report_item_sales_report_by_department.vue'
import ESTeamPurchasePriceHistorySelectedCreditorAndItem from '../views/reports/esteam/purchase_price_history_selected_creditor_and_item.vue'
import ESTeamDebtorsAgeingAnalysisSummary from '../views/reports/esteam/debtors_ageing_analysis_summary.vue'
import ESTeamCreditorBillsEntryList from '../views/reports/esteam/creditor_bills_entry_lists.vue'
import ESTeamPurchaseVolume from '../views/reports/esteam/purchase_volume.vue'
import ESTeamDebtorsAgeingAnalysisDetails from '../views/reports/esteam/debtors_ageing_analysis_details.vue'
import ESTeamDebtorsAgeingAnalysisByTerm from '../views/reports/esteam/debtors_ageing_analysis_by_term.vue'
import ESTeamItemListing from '../views/reports/esteam/item_listing.vue'
import ESTeamPurchaseTransaction from '../views/reports/esteam/purchase_transaction.vue'
import ESTeamPurchaseVolumeAllCreditors from '../views/reports/esteam/purchase_volume_all_creditors.vue'
import ESTeamPurchaseVolumeSelectedItemsVolumeQty from '../views/reports/esteam/purchase_volume_selected_items_volume_qty.vue'
import ESTeamPurchaseReservedItemsRange from '../views/reports/esteam/purchase_reserved_items_range.vue'
import ESTeamPurchaseReservedCategoryRange from '../views/reports/esteam/purchase_reserved_category_range.vue'
import ESTeamPurchaseReservedCustomerItemRange from '../views/reports/esteam/purchase_reserved_customer_item_range.vue'
import ESTeamPurchaseGitItemRange from '../views/reports/esteam/purchase_git_item_range.vue'
import ESTeamPurchaseGitCategoryRange from '../views/reports/esteam/purchase_git_category_range.vue'
import ESTeamPurchaseGitCreditorItemRange from '../views/reports/esteam/purchase_git_creditor_item_range.vue'
import ESTeamPurchaseGitOrderPlanning from '../views/reports/esteam/purchase_git_order_planning.vue'
import ESTeamPurchaseInvoice from '../views/reports/esteam/purchase_invoice.vue'
import ESTeamSalesItemPicking from '../views/reports/esteam/sales_item_picking.vue'
//6 Oct 22
import ESTeamInventoryStockMovement from '../views/reports/esteam/inventory_stock_movement_type_summary.vue'
import ESTeamProductSalesSummaryByCategory from '../views/reports/esteam/product_sales_summary_by_category.vue'
import ESTeamItemBalanceOnHandsByItemRange from '../views/reports/esteam/item_balance_on_hands_by_item_range.vue'
import ESTeamInventoryStockcardByCategory from '../views/reports/esteam/inventory_stockcard_by_category.vue'

import LKKSOA from '../views/reports/lkk/sales_soa.vue'

//13 Dec 22
import ESTeamGST_Summary from '../views/reports/esteam/gst_summary.vue'
//22 Dec 22
import ESTeamArCreditNote from '../views/reports/esteam/ar_credit_note.vue'
//27 DEC 2022
import ESTeamCreditorsAgeingSummary from '../views/reports/esteam/creditors_ageing_analysis_summary.vue'
import ESTeamCreditorsAgeingDetails from '../views/reports/esteam/creditors_ageing_analysis_details.vue'
import ESTeamCreditorsOutstandingLists from '../views/reports/esteam/creditor_outstanding_lists.vue'

//5-6 JAN 2023
import ESTeamSalesVolume from '../views/reports/esteam/sales_volume.vue'
import SalesVolumeCustomerRanking from '../views/reports/esteam/customer_ranking.vue'

//13 JAN 2023
import ESTeamPurchaseGST from '../views/reports/esteam/purchase_gst.vue'

//6 FEB 2023
import ESTeamTransactionReport from '../views/reports/esteam/transaction_report.vue'
import ESTeamReceiptDetails from '../views/reports/esteam/receipt_details.vue'
import ESTeamPaymentDetailsList from '../views/reports/esteam/payment_details.vue'
import ESTeamPaymentReceived from '../views/reports/esteam/payment_received.vue'
import ESTeamPaymentVolume from '../views/reports/esteam/monthly_payment_volume.vue'
import ESTeamMonthlyChequeIssued from '../views/reports/esteam/monthly_cheque_issued.vue'
import ESTeamPaymentEntryLists from '../views/reports/esteam/payment_entry_lists.vue'
import ESTeamOffsetDetails from '../views/reports/esteam/offset_details.vue'
import ESTeamSalesReceipt from '../views/reports/esteam/sales_receipt.vue'

// 2023-03-27
import ESTeamPaymentVoucher from '../views/reports/esteam/payment_voucher.vue'

// 2023-04-12
import ESTeamJVTransactions from '../views/reports/esteam/jv_transactions.vue'
import JournalVoucher from '../views/reports/esteam/journal_voucher.vue'

// 2023-05-15
import BankRecon from '../views/reports/esteam/bank_recon.vue'
import GSTSummary from '../views/reports/esteam/gst_summary.vue'
import GSTDirectEntries from '../views/reports/esteam/gst_direct_entries.vue'
import JVAccountLookup from '../views/reports/esteam/jv_account_lookup.vue'
import TrialBalance from '../views/reports/esteam/trial_balance.vue'
import BankStatementDetails from '../views/reports/esteam/bank_statement_details.vue'
import BalanceSheet from '../views/reports/esteam/balance_sheet.vue'
import PettyCash from '../views/reports/esteam/petty_cash.vue'

import SalespersonGP from '../views/reports/esteam/salesperson_gp.vue'
import SalesGP from '../views/reports/esteam/sales_gp.vue'
import SalesAnalysis from '../views/reports/esteam/sales_analysis.vue'
import SalesInvoiceGP from '../views/reports/esteam/sales_invoice_gp.vue'
import ProductSalesMonthlyByCategory from '../views/reports/esteam/product_sales_monthly_by_category.vue'

const routes = [
  {
    path: '/esteam/product-sales-monthly-by-category/:a',
    name: 'product_sales_monthly_by_category',
    component: ProductSalesMonthlyByCategory
  },
  {
    path: '/esteam/sales-invoice-gp/:a/:document_code',
    name: 'sales_invoice_gp',
    component: SalesInvoiceGP
  },
  {
    path: '/lkk/export-ar-invoices/:a',
    name: 'export_ar_invoices',
    component: LKKExportARInvoices
  },
  {
    path: '/lkk/export-ar-sr/:a',
    name: 'export_ar_sr',
    component: LKKExportARSR
  },
  {
    path: '/esteam/sales-analysis/:a',
    name: 'sales_analysis',
    component: SalesAnalysis
  },
  {
    path: '/esteam/sales-gp/:a',
    name: 'sales_gp',
    component: SalesGP
  },  
  {
    path: '/esteam/salesperson-gp/:a',
    name: 'salesperson_gp',
    component: SalespersonGP
  },  
  {
    path: '/esteam/petty-cash/:a',
    name: 'petty_cash',
    component: PettyCash
  },  
  {
    path: '/esteam/balance-sheet/:a',
    name: 'balance_sheet',
    component: BalanceSheet
  },  
  {
    path: '/esteam/bank-statement-details/:a',
    name: 'bank_statement_details',
    component: BankStatementDetails
  },  
  {
    path: '/esteam/trial-balance/:a',
    name: 'trial_balance',
    component: TrialBalance
  },  
  {
    path: '/esteam/jv-account-lookup/:a/:account_code/:account_tag/:date_from/:date_to',
    name: 'jv_account_lookup',
    component: JVAccountLookup
  },  
  {
    path: '/esteam/gst-direct-entries/:a',
    name: 'gst_direct_entries',
    component: GSTDirectEntries
  },  
  {
    path: '/esteam/bank-recon/:a/:bankaccount_id',
    name: 'bank_recon',
    component: BankRecon
  },
  {
    path: '/esteam/journal-voucher/:a/:code',
    name: 'journal_voucher',
    component: JournalVoucher
  },
  {
    path: '/esteam/jv-transactions/:a',
    name: 'esteam_jv_transactions',
    component: ESTeamJVTransactions
  },
  {
    path: '/esteam/payment-voucher/:a/:document_code',
    name: 'esteam_payment_voucher',
    component: ESTeamPaymentVoucher
  },
  {
    path: '/esteam/sales-receipt/:a/:document_code',
    name: 'esteam_sales_receipt',
    component: ESTeamSalesReceipt
  },
  {
    path:'/esteam/creditors-ageing-analysis-summary/:a',
    name:'esteam_creditors_ageing_analysis_summary',
    component: ESTeamCreditorsAgeingSummary
  },
  {
    path:'/esteam/creditors-ageing-analysis-details/:a',
    name:'esteam_creditors_ageing_analysis_details',
    component: ESTeamCreditorsAgeingDetails
  },
  {
    path:'/esteam/creditors-outstanding-lists/:a',
    name:'esteam_creditor_outstanding_lists',
    component: ESTeamCreditorsOutstandingLists
  },
  {
    path:'/esteam/ar-credit-note/:a/:code',
    name:'esteam_ar_credit_note',
    component: ESTeamArCreditNote
  },
  {
    path: '/esteam/sales-quotation/:a/:document_code/:filename',
    name: 'esteam_sales_quotation',
    component: ESTeamSalesQuotation
  },
  {
    path: '/esteam/purchase-order/:a/:document_code',
    name: 'esteam_purchase_order',
    component: ESTeamPurchaseOrder
  },
  {
    //path:'/esteam/soa/:a/:contact_id/:date_as_at/:currency_code',
    path:'/esteam/soa/:a',
    name: 'esteam_soa',
    component: ESTeamSOA

  },
  {
    path:'/esteam/gst/:a',
    name: 'esteam_gst',
    component: ESTeamGST
  },
  {
    path:'/esteam/gst-summary/:a',
    name: 'esteam_gst_summary',
    component: GSTSummary
  },
  {
    path:'/esteam/inventory_stockcard/:a',
    name: 'esteam_inventory_stockcard',
    component: ESTeamInventoryStockcard
  },
  {
    path:'/esteam/inventory-stockcard-by-category/:a',
    name: 'esteam_inventory_stockcard_by_category',
    component: ESTeamInventoryStockcardByCategory
  },
  {
    //path:'/esteam/debtors-outstanding/:a/:contact_name_from/:contact_name_to/:date_as_at/:show_credit_info',
    path:'/esteam/debtors-outstanding/:a',
    name: 'esteam_debtors',
    component: ESTeamDebtors
  },
  {
    path: '/esteam/sales-invoice/:a/:code/:is_draft',
    name: 'esteam_sales_invoice',
    component: ESTeamSalesInvoice
  },
  {
    path: '/esteam/purchase-invoice/:a/:code',
    name: 'esteam_purchase_invoice',
    component: ESTeamPurchaseInvoice
  },
  {
    path: '/esteam/sales-item-picking/:a/:code',
    name: 'esteam_sales_item_picking',
    component: ESTeamSalesItemPicking
  },
  {
    path: '/esteam/category-listing/:a',
    name: 'esteam_category_listing',
    component: ESTeamCategoryListing
  },
  {
    path: '/esteam/item-listing/:a',
    name: 'esteam_item_listing',
    component: ESTeamItemListing
  },
  {
    path: '/esteam/item-balance-cost-by-category-range/:a',
    name: 'esteam_item_balance_cost_by_category_range',
    component: ESTeamItemBalanceCostByCategoryRange
  },
  {
    path: '/esteam/item-balance-on-hands-by-category-range/:a',
    name: 'esteam_item_balance_on_hands_by_category_range',
    component: ESTeamItemBalanceOnHandsByCategoryRange
  },
  {
    path:'/esteam/item-balance-on-hands-by-item-range/:a',
    name:'esteam_item_balance_on_hands_by_item_range',
    component: ESTeamItemBalanceOnHandsByItemRange
  },
  {
    path: '/esteam/stock-value-report-summary/:a',
    name: 'esteam_stock_value_report_summary',
    component: ESTeamStockValueReportSummary
  },
  {
    path: '/esteam/product-sales-item-sales-report-by-department/:a',
    name: 'esteam_product_sales_item_sales_report_by_department',
    component: ESTeamProductSalesReportItemSalesReportByDepartment
  },
  {
    path: '/esteam/product-sales-summary-by-category/:a',
    name: 'esteam_product_sales_summary_by_category',
    component:ESTeamProductSalesSummaryByCategory
  },
  {
    path:'/esteam/purchase-price-history-selected-creditor-and-item/:a',
    name: 'esteam_purchase_price_history_selected_creditor_and_item',
    component: ESTeamPurchasePriceHistorySelectedCreditorAndItem
  },
  {
    path: '/esteam/debtors-ageing-analysis-summary/:a',
    name: 'esteam_debtors_ageing_analysis_summary',
    component: ESTeamDebtorsAgeingAnalysisSummary
  },
  {
    path: '/esteam/debtors-ageing-analysis-details/:a',
    name: 'esteam_debtors_ageing_analysis_details',
    component: ESTeamDebtorsAgeingAnalysisDetails
  },
  {
    path: '/esteam/debtors-ageing-analysis-by-term/:a',
    name: 'esteam_debtors_ageing_analysis_by_term',
    component: ESTeamDebtorsAgeingAnalysisByTerm
  },
  {
    path:'/esteam/creditor-bills-entry-lists/:a',
    name:'esteam_creditor_bills_entry_lists',
    component:  ESTeamCreditorBillsEntryList
  },
  {
    path:'/esteam/purchase-transaction/:a',
    name:'esteam_purchase_transaction',
    component: ESTeamPurchaseTransaction
  },
  {
    path:'/esteam/purchase-volume/:a',
    name:'esteam_purchase-volume',
    component:  ESTeamPurchaseVolume
  },
  {
    path:'/esteam/purchase-volume-all-creditors/:a',
    name:'esteam_purchase_volume_all_creditors',
    component: ESTeamPurchaseVolumeAllCreditors
  },
  {
    path:'/esteam/purchase-volume-selected-items-volume-qty/:a',
    name:'esteam_purchase_volume_selected_items_volume_qty',
    component: ESTeamPurchaseVolumeSelectedItemsVolumeQty
  },
  {
    path:'/esteam/purchase-reserved-items-range/:a',
    name:'esteam_purchase_reserved_items_range',
    component:ESTeamPurchaseReservedItemsRange
  },
  {
    path:'/esteam/purchase-reserved-category-range/:a',
    name:'esteam_purchase_reserved_category_range',
    component:ESTeamPurchaseReservedCategoryRange
  },
  {
    path:'/esteam/purchase-reserved-customer-item-range/:a',
    name:'esteam_purchase_reserved_customer_item_range',
    component: ESTeamPurchaseReservedCustomerItemRange
  },
  {
    path:'/esteam/purchase-git-item-range/:a',
    name:'esteam_purchase_git_item_range',
    component:ESTeamPurchaseGitItemRange
  },
  {
    path:'/esteam/purchase-git-category-range/:a',
    name:'esteam_purchase_git_category_range',
    component:ESTeamPurchaseGitCategoryRange
  },
  {
    path:'/esteam/purchase-git-creditor-item-range/:a',
    name:'esteam_purchase_git_creditor_item_range',
    component:ESTeamPurchaseGitCreditorItemRange
  },
  {
    path:'/esteam/purchase-git-order-planning/:a',
    name:'esteam_purchase_git_order_planning',
    component:ESTeamPurchaseGitOrderPlanning
  },
  {
    path:'/esteam/inventory-stock-movement-type-summary/:a',
    name:'esteam_inventory_stock_movement_type_summary',
    component: ESTeamInventoryStockMovement
  },
   {
    path:'/esteam/sales-volume/:a',
    name:'esteam_sales_volume',
    component: ESTeamSalesVolume
  },
  {
    path:'/esteam/sales-volume-customer-ranking/:a',
    name:'esteam_customer_ranking',
    component: SalesVolumeCustomerRanking
  },
  {
    path:'/esteam/purchase-gst/:a',
    name:'esteam_purchase-gst',
    component: ESTeamPurchaseGST
  },
  {
    path:'/esteam/transaction-report/:a',
    name:'esteam_transaction_report',
    component: ESTeamTransactionReport
  },
  {
    path:'/esteam/receipt-details/:a',
    name:'esteam_receipt_details',
    component: ESTeamReceiptDetails
  },
  {
    path:'/esteam/payment-details-list/:a',
    name:'esteam_payment_details_list',
    component: ESTeamPaymentDetailsList
  },
  {
    path:'/esteam/payment-received/:a',
    name:'esteam_payment_received',
    component:ESTeamPaymentReceived
  },
  {
    path:'/esteam/payment-volume/:a',
    name:'esteam_payment_volume',
    component:ESTeamPaymentVolume
  },
  {
    path:'/esteam/monthly-cheque-issued/:a',
    name:'esteam_monthly_cheque_issued',
    component:ESTeamMonthlyChequeIssued
  },
  {
    path:'/esteam/payment-entry-lists/:a',
    name:'esteam_payment_entry_lists',
    component:ESTeamPaymentEntryLists
  },
  {
    path:'/esteam/offset-details/:a',
    name:'esteam_offset_details',
    component:ESTeamOffsetDetails
  },


  //LKK
  {
    path:'/lkk/sales_soa/:a',
    name: 'lkk_sales_soa',
    component: LKKSOA
  },
  {
    path: '/lkk/preliminary_advice/:id',
    name: 'lkk_preliminary_advice',
    component: LKKPreliminaryAdvice
  },
  {
    path: '/lkk/sale_invoices/:id',
    name: 'lkk_sales_invoice',
    component: LKKSalesInvoice
  },
  {
    path: '/lkk/survey/:id',
    name: 'lkk_survey',
    component: LKKSurvey
  },
  {
    path: '/lkk/survey_adjustment/:id',
    name: 'lkk_survey_adjustment',
    component: LKKSurveyAdjustment
  },
   {
    path: '/lkk/survey_photos_2x3/:id',
    name: 'lkk_survey_photos_2x3',
    component: LKKSurveyPhotos
  },
  {
    path: '/lkk/survey_photos_2x2/:id',
    name: 'lkk_survey_photos_landscape',
    component: LKKSurveyPhotosLandscape
  },

  {
    path: '/hkgrocery/sales_analysis_by_month/:a',
    name: 'hkg_sales_analysis_by_month',
    component: SalesAnalysisByMonth
  },

  {
    path: '/hkgrocery/sales_history_by_product/:a',
    name: 'sales_history_by_product',
    component: SalesHistoryByProduct
  },
  {
    path: '/hkgrocery/sales_history_by_client/:a',
    name: 'hkg_sales_history_client',
    component: SalesAnalysisByClient
  },
  {
    path: '/hkgrocery/stock_closing_balance/:a',
    name: 'hkg_stock_closing_balance',
    component: HKGROCERYStockClosingBalance
  },

   {
    path: '/hkgrocery/bank_reconciliation/:a/:bankaccount_id',
    name: 'hkg_bank_reconcilation',
    component: BankReconciliation
  },
    {
    path: '/hkgrocery/purchase_history/:a',
    name: 'hkg_purchase_history',
    component: PurchaseHistory
  },

     {
    path: '/hkeda/sales_receipt/:a/:receipt_id',
    name: 'hkeda_sales_receipt',
    component: SalesReceipt
  },
     {
    path: '/hkeda/sales/:a/:sales_id',
    name: 'hkeda_sales',
    component: Sales
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
