<template>

<div id="viewer-host">
<JSViewer ref="reportViewer"></JSViewer></div>
</template>
<script>
/*eslint-disable */
import { Viewer } from '@grapecity/activereports-vue'
import '@grapecity/activereports/styles/ar-js-ui.css'
import '@grapecity/activereports/styles/ar-js-viewer.css'

export default {
  name: 'esteam_debtors',
  components: { JSViewer: Viewer },
  async mounted()
  {
      this.$refs.reportViewer
        .Viewer()
        .open("/reports/esteam/ESTeam-Debtor_Outstanding.rdlx-json", {
          ReportParams: [
            {
              Name: "access_token",
              Value: [this.$route.params.a],
            },
            /*{
              Name: "contact_name_from",
              Value: [this.$route.params.contact_name_from]
            },
            {
              Name: "contact_name_to",
              Value: [this.$route.params.contact_name_to]
            },
            {
              Name: "date_as_at",
              Value: [this.$route.params.date_as_at]
            },
            {
              Name: "show_credit_info",
              Value: [this.$route.params.show_credit_info]
            },**/
          ],
        });
  },
}
</script>
