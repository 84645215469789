/* eslint-disable */
// import { countReset } from 'console'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Core } from "@grapecity/activereports";




// import { Core } from "@grapecity/activereports";
// Core.setLicenseKey('*.unityerp.com,647615946866432#B0xlWehlWO48Ebzg6TPVWa4tGWvNXQBl7R0RmaE3UValDTjJEexZVQ8h7V9dmV7gmQwhHZvs4Z8JzTS96KOZGeFVTTyh4dsJXQsB5Lrt4ZrkGMvZjM9IEdThXNvxUVlJDOThmMupWZvI4TvdjcY5GWWNDcXVXM4kTNMdUdiRDbpRmZzNXWHhkWrtWaLljS6RXaVJjawBTR6UTQwMzcNFXZykGTWZldzcmMvRmSzsidvcDMFplQCBFU7NlQ4kDUzJUcs54KNBzb9IXNJdXUThFdpFWd6Bjei36SVV5SwQHW83ETQJjWuhnU5lkWKpnU984TiojITJCLiYkQ6cTO8Q4NiojIIJCL7YDM9UjM7ITN0IicfJye#4Xfd5nIPNUUKJiOiMkIsIyMWByUKRncvBXZSVmdpR7YBJiOi8kI1tlOiQmcQJCLikTMzMjNwASNwcDMyIDMyIiOiQncDJCLi46bj9CcyVWe4lmb59iKiojIz5GRiwiIu3Wa4Fmbl9WeyJiOiEmTDJCLiIzM4YjN8YDN9UTM6cDN6IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRBTbHR7VCZXT0NTMDRjRQFnaBFHa6dTeyBzbulkbycWbEZVeBd6U6MndTlDSZxWOQNTMIlUULh5dvF6K6UlRnhVOSdjSMZVT0lkT63iY4ckemtWcrElT8g7aPlkehBHWIRHUu1Xc')
Core.setLicenseKey('*.unityerp.com,647615946866432#B0xlWehlWO48Ebzg6TPVWa4tGWvNXQBl7R0RmaE3UValDTjJEexZVQ8h7V9dmV7gmQwhHZvs4Z8JzTS96KOZGeFVTTyh4dsJXQsB5Lrt4ZrkGMvZjM9IEdThXNvxUVlJDOThmMupWZvI4TvdjcY5GWWNDcXVXM4kTNMdUdiRDbpRmZzNXWHhkWrtWaLljS6RXaVJjawBTR6UTQwMzcNFXZykGTWZldzcmMvRmSzsidvcDMFplQCBFU7NlQ4kDUzJUcs54KNBzb9IXNJdXUThFdpFWd6Bjei36SVV5SwQHW83ETQJjWuhnU5lkWKpnU984TiojITJCLiYkQ6cTO8Q4NiojIIJCL7YDM9UjM7ITN0IicfJye#4Xfd5nIPNUUKJiOiMkIsIyMWByUKRncvBXZSVmdpR7YBJiOi8kI1tlOiQmcQJCLikTMzMjNwASNwcDMyIDMyIiOiQncDJCLi46bj9CcyVWe4lmb59iKiojIz5GRiwiIu3Wa4Fmbl9WeyJiOiEmTDJCLiIzM4YjN8YDN9UTM6cDN6IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRBTbHR7VCZXT0NTMDRjRQFnaBFHa6dTeyBzbulkbycWbEZVeBd6U6MndTlDSZxWOQNTMIlUULh5dvF6K6UlRnhVOSdjSMZVT0lkT63iY4ckemtWcrElT8g7aPlkehBHWIRHUu1Xc')
createApp(App).use(router).mount('#app')
