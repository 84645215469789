<template>

<div id="viewer-host">
<JSViewer ref="reportViewer"></JSViewer></div>
</template>
<script>
/*eslint-disable */
import { Viewer } from '@grapecity/activereports-vue'
import '@grapecity/activereports/styles/ar-js-ui.css'
import '@grapecity/activereports/styles/ar-js-viewer.css'

export default {
name: 'esteam_purchase_git_order_planning',
components: { JSViewer: Viewer },
async mounted()
{
  this.$refs.reportViewer
    .Viewer()
    .open("/reports/esteam/ESTeam-Purchase-Git-Order-Planning.rdlx-json", {
      ReportParams: [
        {
          Name: "access_token",
          Value: [this.$route.params.a],
        },
      ],
    });
},
}
</script>
