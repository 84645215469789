<template>

<div id="viewer-host">
<JSViewer ref="reportViewer"></JSViewer></div>
</template>
<script>
/*eslint-disable */
import { Viewer } from '@grapecity/activereports-vue'
import '@grapecity/activereports/styles/ar-js-ui.css'
import '@grapecity/activereports/styles/ar-js-viewer.css'


export default {
  name: 'preliminary_advice',
  components: { JSViewer: Viewer },
  async mounted()
  {

      this.$refs.reportViewer
        .Viewer()
        .open("/reports/lkk/LKK-Preliminary_Advice.rdlx-json", {
          ReportParams: [
            {
              Name: "code",
              Value: [this.$route.params.id],
            },
          ],
        });
  },
}
</script>
